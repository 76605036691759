import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import HubIcon from '@mui/icons-material/Hub';
import MenuIcon from '@mui/icons-material/Menu';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { IconButton } from '@mui/material';
import {
  StyledContainer,
  StyledDrawer,
  StyledHeader,
  StyledOutlet,
  StyledRoot,
} from './styles';
import DrawerMenu from '../../components/DrawerMenu';
import useWindowWidth from '../../hooks/useWindowWidth';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { formatDateHeader } from '../../utils/date';

const Dashboard: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  const isAdmin = useMemo(() => {
    return authenticate?.user?.role === 'admin';
  }, [authenticate?.user?.role]);

  const [openDrawer, setOpenDrawe] = useState(false);

  const handleChangeDrawer = () => {
    setOpenDrawe((previewValue) => !previewValue);
  };

  const width = useWindowWidth();

  const drawerMenuItems = useMemo(() => {
    const items = [
      {
        label: 'Home',
        icon: <SpaceDashboardIcon />,
        path: '/dash',
      },
    ];

    if (isAdmin) {
      items.push({
        label: 'Cadastro de Usuários',
        icon: <PeopleAltIcon />,
        path: '/dash/users',
      });

      items.push({
        label: 'Cadastro de Clientes',
        icon: <BusinessIcon />,
        path: '/dash/clients',
      });
    }

    items.push(
      ...[
        {
          label: 'Atendimentos',
          icon: <WorkHistoryIcon />,
          path: '/dash/requests',
        },
        {
          label: 'Tarefas',
          icon: <TaskAltIcon />,
          path: '/dash/tasks',
        },
      ]
    );

    items.push({
      label: 'Base de conhecimento',
      icon: <HubIcon />,
      path: '/dash/hub',
    });

    if (isAdmin) {
      items.push({
        label: 'Fechamentos',
        icon: <MonetizationOnIcon />,
        path: '/dash/closing',
      });
    }

    return items;
  }, [isAdmin]);

  return (
    <StyledRoot>
      <StyledDrawer>
        <DrawerMenu
          open={openDrawer}
          onClose={() => setOpenDrawe(false)}
          variant={width > 900 ? 'permanent' : 'temporary'}
          options={drawerMenuItems}
        />
      </StyledDrawer>
      <StyledContainer>
        <StyledHeader>
          {width < 900 && (
            <IconButton onClick={handleChangeDrawer}>
              <MenuIcon />
            </IconButton>
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <div>Bem vindo, {authenticate?.user?.name || 'amigo'}</div>
            <div>{formatDateHeader(time)}</div>
          </div>
        </StyledHeader>
        <StyledOutlet>
          <Outlet />
        </StyledOutlet>
      </StyledContainer>
    </StyledRoot>
  );
};

export default Dashboard;
