/* eslint-disable no-underscore-dangle */
import React, { ReactElement } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DetailsInfo, {
  DetailsSectionProps,
} from '../../../components/DetailsInfo';
import { StyledEditContainer } from './styles';

interface Props {
  data: any;
  handleDelete: () => void;
}

const HubClientDetails: React.FC<Props> = ({
  data,
  handleDelete,
}): ReactElement => {
  const handleEventAbort = (event: any) => {
    event.stopPropagation();
  };

  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      onClick={handleEventAbort}
    >
      <StyledEditContainer onClick={handleDelete}>
        <DeleteIcon fontSize="large" color="error" />
        <div>Deletar informações </div>
      </StyledEditContainer>
      <DetailsInfo
        isLoading={false}
        sections={
          [
            {
              title: '',
              rows: [
                {
                  label: 'URL ou IP',
                  key: 'url',
                },
                {
                  label: 'Nome',
                  key: 'name',
                },
                {
                  label: 'Descrição',
                  key: 'folder',
                },
                {
                  label: 'Usuário',
                  key: 'username',
                },
                {
                  label: 'Senha',
                  key: 'password',
                },
                {
                  label: 'Notas',
                  key: 'description',
                  isHtml: true,
                },
              ],
              data,
            },
          ].filter((item) => !!item) as DetailsSectionProps[]
        }
      />
    </div>
  );
};

export default HubClientDetails;
