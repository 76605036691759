import { RequestFormProps } from '../components/AddRequestForm/schema';
import api from '../services/api';

export const getClosings = async (token: string): Promise<any> => {
  const result = await api.get('/api/closings', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getClosingDetails = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.get(`/api/closings/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const approveRequest = async (
  token: string,
  closingId: string,
  id: string
): Promise<any> => {
  const result = await api.post(
    `/api/closings/${closingId}/requests/${id}/approve`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getClosingRequestDetails = async (
  token: string,
  id: string,
  requestId: string
): Promise<any> => {
  const result = await api.get(`/api/closings/${id}/requests/${requestId}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const editClosingRequest = async (
  data: RequestFormProps,
  token: string,
  id: string,
  requestId: string
): Promise<void> => {
  await api.patch(`/api/closings/${id}/requests/${requestId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getClosings,
  getClosingDetails,
  getClosingRequestDetails,
  editClosingRequest,
};
