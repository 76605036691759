/* eslint-disable no-underscore-dangle */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import TableTitle from '../../components/Table/TableTitle';

import Backbutton from '../../components/Backbutton';
import TableExpansive from '../../components/TableExpansive';
import HubClientDetails from './HubClientDetails';
import { deleteHub, getHubClient } from '../../api/hub';
import NotFoundItems from '../../components/NotFoundItems';
import ConfirmModal from '../../components/ConfirmModal';

const HubClient: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { clientId } = useParams();

  const [search, setSearch] = useState('');

  const [expandAll, setExpandAll] = useState(false);

  const [data, setData] = useState<any>({});

  const [open, setOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState('');

  const handleOpenDeleteModal = (data: any) => {
    setOpen(true);
    setDataToDelete(data._id);
  };

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && clientId) {
        const data = await getHubClient(clientId, authenticate.token);

        setData(data?.client || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token, clientId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDelete = async (id: string) => {
    setOpen(false);
    try {
      if (authenticate.token) {
        await deleteHub(id, authenticate.token);

        getData();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const dataFiltered = useMemo(() => {
    if (!data?.hubs) {
      return [];
    }

    if (!search) {
      return data?.hubs;
    }

    const searchUppercase = search.toUpperCase();

    return data?.hubs.filter((item: any) => {
      return (
        item.name?.toUpperCase()?.includes(searchUppercase) ||
        item.folder?.toUpperCase()?.includes(searchUppercase) ||
        item.url?.toUpperCase()?.includes(searchUppercase) ||
        item.description?.toUpperCase()?.includes(searchUppercase)
      );
    });
  }, [search, data?.hubs]);

  const handleExpensive = (data: any) => {
    return (
      <HubClientDetails
        data={data}
        handleDelete={() => handleOpenDeleteModal(data)}
      />
    );
  };

  return (
    <>
      <StyledRoot>
        <div style={{ display: 'flex', flex: 1 }}>
          <Backbutton
            path="/dash/hub"
            labels={['Base de conhecimento', data ? data.name : 'Client name']}
          />
          <TableTitle
            title=""
            search={{
              handleSearch: (value: string) => setSearch(value),
            }}
            button={{
              text: expandAll ? 'Ocultar todos' : 'Expandir todos',
              icon: expandAll ? <ExpandLessIcon /> : <ExpandMoreIcon />,
              onAction: () => setExpandAll(!expandAll),
            }}
            secondaryButton={{
              text: 'Novo registro',
              onAction: () => navigate('/dash/hub/create'),
            }}
          />
        </div>

        <TableExpansive
          header={{
            backgroundColor: '#38A658',
            color: '#fff',
            columns: [
              {
                label: 'URL ou IP',
              },
              {
                label: 'Nome',
              },
              {
                label: 'Pasta',
              },
            ],
          }}
          body={{
            rows: [{ key: 'url' }, { key: 'name' }, { key: 'folder' }],
          }}
          data={dataFiltered || []}
          handleExpansive={handleExpensive}
          expandAll={expandAll}
        />

        {!dataFiltered.length && (
          <NotFoundItems
            label={
              data?.hubs?.length
                ? 'Nenhum resultado encontrado para essa busca!'
                : undefined
            }
          />
        )}
      </StyledRoot>
      <ConfirmModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => handleDelete(dataToDelete)}
        title="Deletar usuário"
        message="Deseja confirmar a exclusão do usuário?"
      />
    </>
  );
};

export default HubClient;
