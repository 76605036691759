/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  RequestFormProps,
  StatusRequestEnum,
  TypeRequestEnum,
  initialRequestFormValues,
  validationSchema,
} from './schema';
import { formatClientType } from '../../pages/Clients/utils';
import { moneyMask } from '../../utils/money';

interface Props {
  clients: any[];
  task?: any;
  isLoading: boolean;
  onSubmit: (values: RequestFormProps) => void;
}

const AddRequestForm: React.FC<Props> = ({
  clients,
  task,
  isLoading,
  onSubmit,
}): React.ReactElement => {
  const uncompletedRequestKey = useMemo(() => {
    return task ? `uncompleted-request-task-${task.id}` : 'uncompleted-request';
  }, [task]);

  const navigate = useNavigate();

  const [isLoadingPage, setLoadingPage] = useState(true);

  const prepareTime = (date: Date): string => {
    const startTime = new Date(date);

    const hours =
      startTime.getHours() >= 10
        ? startTime.getHours()
        : `0${startTime.getHours()}`;

    const minutes =
      startTime.getMinutes() >= 10
        ? startTime.getMinutes()
        : `0${startTime.getMinutes()}`;

    return `${hours}:${minutes}`;
  };

  const prepareOnlyDate = (date: Date): string => {
    const dateParse = new Date(date);

    const day =
      dateParse.getDate() >= 10
        ? dateParse.getDate()
        : `0${dateParse.getDate()}`;

    const month =
      dateParse.getMonth() + 1 >= 10
        ? dateParse.getMonth() + 1
        : `0${dateParse.getMonth() + 1}`;

    const year =
      dateParse.getFullYear() >= 10
        ? dateParse.getFullYear()
        : `0${dateParse.getFullYear()}`;

    return `${year}-${month}-${day}`;
  };

  const initialValues = useMemo(() => {
    if (task) {
      const startDateTime = prepareTime(task.startDate);

      return {
        client: task?.client?._id,
        startDateTime: startDateTime as any,
        startDate: prepareOnlyDate(task.startDate),

        type: task.type,
      };
    }

    return initialRequestFormValues;
  }, [task]);

  const uncompletedRequest = useMemo(() => {
    const uncompletedRequest = localStorage.getItem(uncompletedRequestKey);
    if (uncompletedRequest) {
      return JSON.parse(uncompletedRequest);
    }
    return {};
  }, [uncompletedRequestKey]);

  const formik = useFormik({
    initialValues: task
      ? { ...initialRequestFormValues, ...uncompletedRequest, ...initialValues }
      : { ...initialRequestFormValues, ...uncompletedRequest },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    localStorage.setItem(uncompletedRequestKey, JSON.stringify(formik.values));
  }, [formik.values, uncompletedRequestKey]);

  const handleChangeClient = (event: any, value: any) => {
    formik.setFieldValue('client', value?.value || '');
  };

  const handleChangeMoney =
    (key: string) =>
    (event: any): void => {
      const value = moneyMask(event.target.value);

      formik.setFieldValue(key, value);
    };

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false);
    }, 50);
  }, []);

  const prepareLabelAutocomplete = (clients: any, id: string) => {
    const client = clients.find((item: any) => item.id === id);

    if (client) {
      return `${client?.name} ${formatClientType(client?.type)}`;
    }

    return '';
  };

  if (isLoadingPage) {
    return <div></div>;
  }

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          {!task && (
            <FormGroup>
              <FormControlLabel
                checked={formik.values.singleClient}
                onChange={(e, checked) =>
                  formik.setFieldValue('singleClient', checked)
                }
                control={<Switch />}
                label="Cliente avulso"
              />
            </FormGroup>
          )}

          <StyledContentTextFields>
            <StyledContentTextFields>
              {task && (
                <Autocomplete
                  fullWidth
                  options={[
                    {
                      label: `${task.client.name} ${formatClientType(
                        task.client.type
                      )}`,
                      value: task.client._id,
                    },
                  ]}
                  disabled
                  onChange={handleChangeClient}
                  onBlur={formik.handleBlur}
                  noOptionsText="Nenhuma opção encontrada"
                  defaultValue={
                    task
                      ? {
                          label: `${task?.client?.name} ${formatClientType(
                            task?.client?.type
                          )}`,
                          value: task.client._id,
                        }
                      : undefined
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="client"
                      variant="outlined"
                      name="client"
                      label="Cliente"
                      InputLabelProps={{
                        style: {
                          fontSize: 18,
                          textTransform: 'uppercase',
                          background: '#fff',
                          paddingRight: 5,
                          color: '#000',
                        },
                        shrink: true,
                      }}
                      value={formik.values.client}
                      error={
                        formik.touched.client && Boolean(formik.errors.client)
                      }
                      helperText={formik.touched.client && formik.errors.client}
                    />
                  )}
                />
              )}

              {!task && !formik.values.singleClient && (
                <Autocomplete
                  fullWidth
                  options={clients.map((item) => ({
                    label: `${item.name} ${formatClientType(item.type)}`,
                    value: item.id,
                  }))}
                  disabled={formik.values.singleClient}
                  onChange={handleChangeClient}
                  onBlur={formik.handleBlur}
                  defaultValue={
                    uncompletedRequest
                      ? {
                          label: prepareLabelAutocomplete(
                            clients,
                            uncompletedRequest.client
                          ),
                          value: uncompletedRequest.client,
                        }
                      : undefined
                  }
                  noOptionsText="Nenhuma opção encontrada"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="client"
                      variant="outlined"
                      name="client"
                      label="Cliente"
                      required
                      InputLabelProps={{
                        style: {
                          fontSize: 18,
                          textTransform: 'uppercase',
                          background: '#fff',
                          paddingRight: 5,
                          color: '#000',
                        },
                        shrink: true,
                      }}
                      value={formik.values.client}
                      error={
                        formik.touched.client && Boolean(formik.errors.client)
                      }
                      helperText={formik.touched.client && formik.errors.client}
                    />
                  )}
                />
              )}

              {!task && formik.values.singleClient && (
                <TextField
                  fullWidth
                  id="clientName"
                  type="clientName"
                  variant="outlined"
                  name="clientName"
                  required
                  label="Nome do cliente"
                  InputLabelProps={{
                    style: {
                      fontSize: 18,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  value={formik.values.clientName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.clientName &&
                    Boolean(formik.errors.clientName)
                  }
                  helperText={
                    formik.touched.clientName && formik.errors.clientName
                  }
                />
              )}
            </StyledContentTextFields>

            <StyledContentTextFields>
              <TextField
                fullWidth
                id="type"
                variant="outlined"
                name="type"
                label="Tipo do atendimento"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                select
                required
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
              >
                <MenuItem value={TypeRequestEnum.REMOTO}>Remoto</MenuItem>
                <MenuItem value={TypeRequestEnum.PRESENTIAL}>
                  Presencial
                </MenuItem>
              </TextField>

              <TextField
                fullWidth
                id="status"
                variant="outlined"
                name="status"
                label="Status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                select
                required
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
              >
                <MenuItem value={StatusRequestEnum.PENDING}>Em aberto</MenuItem>
                <MenuItem value={StatusRequestEnum.COMPLETED}>
                  Finalizado
                </MenuItem>
              </TextField>
            </StyledContentTextFields>
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="startDate"
              type="date"
              variant="outlined"
              name="startDate"
              label="Data de início"
              inputProps={{
                max: task ? undefined : new Date().toISOString().split('T')[0],
              }}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              required
              hiddenLabel
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
            />

            <TextField
              fullWidth
              id="startDateTime"
              type="time"
              variant="outlined"
              name="startDateTime"
              label="Hora de início"
              hiddenLabel
              value={formik.values.startDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              error={
                formik.touched.startDateTime &&
                Boolean(formik.errors.startDateTime)
              }
              helperText={
                formik.touched.startDateTime && formik.errors.startDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="endDate"
              type="date"
              variant="outlined"
              name="endDate"
              label="Data de fim"
              required
              inputProps={{
                min: formik.values.startDate
                  ? new Date(formik.values.startDate || '')
                      .toISOString()
                      .split('T')[0]
                  : undefined,
                max: new Date().toISOString().split('T')[0],
              }}
              hiddenLabel
              value={formik.values.endDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="endDateTime"
              type="time"
              variant="outlined"
              name="endDateTime"
              label="Hora de fim"
              hiddenLabel
              required
              value={formik.values.endDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.endDateTime && Boolean(formik.errors.endDateTime)
              }
              helperText={
                formik.touched.endDateTime && formik.errors.endDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          </StyledContentTextFields>

          <TextField
            fullWidth
            id="description"
            type="description"
            variant="outlined"
            multiline
            rows={15}
            required
            name="description"
            label="Descrição do serviço"
            value={formik.values.description}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />

          <TextField
            fullWidth
            id="observation"
            type="observation"
            variant="outlined"
            multiline
            rows={2}
            name="observation"
            label="Observação (interna)"
            value={formik.values.observation}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.observation && Boolean(formik.errors.observation)
            }
            helperText={formik.touched.observation && formik.errors.observation}
          />

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="food"
              variant="outlined"
              name="food"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Alimentação"
              value={formik.values.food}
              onChange={handleChangeMoney('food')}
              onBlur={formik.handleBlur}
              error={formik.touched.food && Boolean(formik.errors.food)}
              helperText={formik.touched.food && formik.errors.food}
            />
            <TextField
              fullWidth
              id="taxRoad"
              variant="outlined"
              name="taxRoad"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Pedágio"
              value={formik.values.taxRoad}
              onChange={handleChangeMoney('taxRoad')}
              onBlur={formik.handleBlur}
              error={formik.touched.taxRoad && Boolean(formik.errors.taxRoad)}
              helperText={formik.touched.taxRoad && formik.errors.taxRoad}
            />

            <TextField
              fullWidth
              id="parking"
              variant="outlined"
              name="parking"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Estacionamento"
              value={formik.values.parking}
              onChange={handleChangeMoney('parking')}
              onBlur={formik.handleBlur}
              error={formik.touched.parking && Boolean(formik.errors.parking)}
              helperText={formik.touched.parking && formik.errors.parking}
            />
          </StyledContentTextFields>
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/requests')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default AddRequestForm;
