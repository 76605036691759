import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  StyledForm,
  StyledContentSubtitle,
  StyledLogo,
  StyledRoot,
  // StyledTitle,
  StyledLine,
  StyledLineText,
  StyledTextField,
  StyledContent,
} from './styles';
import {
  LoginFormProps,
  initialLoginFormValues,
  validationSchema,
} from './schema';

interface Props {
  isLoading: boolean;
  onSubmit: (values: LoginFormProps) => void;
}

const LoginForm: React.FC<Props> = ({
  isLoading,
  onSubmit,
}): React.ReactElement => {
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: initialLoginFormValues,
    validationSchema,
    validateOnBlur: false,
    onSubmit,
  });

  return (
    <StyledRoot>
      <StyledLogo src="/images/rect16.png" />

      <StyledContent>
        <StyledForm onSubmit={formik.handleSubmit}>
          {/* <StyledTitle>Seja bem vindo</StyledTitle> */}

          <StyledContentSubtitle>
            <StyledLine />
            <StyledLineText>Área de login</StyledLineText>
          </StyledContentSubtitle>

          <StyledTextField
            fullWidth
            id="email"
            variant="outlined"
            name="email"
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
              },
              shrink: true,
            }}
            label="E-MAIL"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            id="password"
            variant="outlined"
            name="password"
            label="SENHA"
            type={showPassword ? 'text' : 'password'}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
              },
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            style={{
              height: 45,
            }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Entrar'}
          </Button>
        </StyledForm>
      </StyledContent>
    </StyledRoot>
  );
};

export default LoginForm;
