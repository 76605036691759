/* eslint-disable no-underscore-dangle */
import React, { ReactElement } from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import DetailsInfo from '../../../components/DetailsInfo';
import {
  calculateDiffDatesInSeconds,
  formatDate,
  formatSeconds,
} from '../../../utils/date';
import { formatMoney } from '../../../utils/money';
import { StyledEditRequestContainer, StyledEditRequestItem } from './styles';

interface Props {
  requestDetails: any;
  handleEdit: () => void;
  handleApprove: () => void;
}

const ClosingRequestDetailsList: React.FC<Props> = ({
  requestDetails,
  handleEdit,
  handleApprove,
}): ReactElement => {
  const formatStatus = (status: string): string => {
    if (status === 'pending') {
      return 'Pendente';
    }

    if (status === 'approved') {
      return 'Aprovado';
    }

    return 'Finalizado';
  };

  const formatType = (status: string): string => {
    if (status === 'remote') {
      return 'Remoto';
    }

    return 'Presencial';
  };

  const handleButtonEdit = (event: any) => {
    event.stopPropagation();

    handleEdit();
  };

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {['pending', 'completed'].includes(requestDetails?.status) && (
        <StyledEditRequestContainer>
          <StyledEditRequestItem onClick={handleButtonEdit}>
            <EditNoteIcon fontSize="large" color="info" />
            <div>Editar atendimento </div>
          </StyledEditRequestItem>

          <StyledEditRequestItem onClick={handleApprove}>
            <PlaylistAddCheckIcon fontSize="large" color="primary" />
            <div>Aprovar atendimento </div>
          </StyledEditRequestItem>
        </StyledEditRequestContainer>
      )}

      <DetailsInfo
        isLoading={false}
        sections={[
          {
            title: 'Consultor',
            rows: [
              {
                label: 'Nome',
                key: 'name',
              },
              {
                label: 'E-mail',
                key: 'email',
              },
            ],
            data: requestDetails?.user,
          },

          {
            title: 'Atendimento',
            rows: [
              {
                label: 'Data de início',
                key: 'startDateClosing',
                transform: formatDate,
              },
              {
                label: 'Data de fim',
                key: 'endDateClosing',
                transform: formatDate,
              },
              {
                label: 'Duração',
                key: 'endDateClosing',
                transformBody: (params: any) => {
                  const seconds = calculateDiffDatesInSeconds(
                    params.startDateClosing,
                    params.endDateClosing
                  );
                  return formatSeconds(seconds);
                },
              },
              {
                label: 'Tipo do atendimento',
                key: 'type',
                transform: formatType,
              },
              {
                label: 'Status',
                key: 'status',
                transform: formatStatus,
              },
              {
                label: 'Descrição',
                key: 'description',
                isHtml: true,
              },
              {
                label: 'Observação (interna)',
                key: 'observation',
                isHtml: true,
              },
            ],
            data: requestDetails,
          },
          {
            title: 'Cobrança',
            rows: [
              {
                label: 'Cobrar atendimentos',
                key: 'chargeRequest',
                transform: (charge) => (charge ? 'Sim' : 'Não'),
              },
              {
                label: 'Valor total',
                key: 'total',
                transform: formatMoney,
              },
              {
                label: 'Duração',
                key: 'duration',
                transformBody: (params: any) => {
                  const seconds = calculateDiffDatesInSeconds(
                    params.startDateClosing,
                    params.endDateClosing
                  );
                  return formatSeconds(seconds);
                },
              },
              {
                label: 'Horas normais',
                key: 'hours',
                transform: formatSeconds,
              },
              {
                label: 'Valor da hora',
                key: 'valuePerHour',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Total da hora normal',
                key: 'valuePerHour',
                transformBody: (params: any) => {
                  const total = (params.hours / 3600) * params.valuePerHour;
                  return formatMoney(total);
                },
              },
              {
                label: 'Horas extras',
                key: 'hoursExtra',
                transform: formatSeconds,
              },
              {
                label: 'Valor da hora extra',
                key: 'valuePerExtraHour',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Total da hora extra',
                key: 'valuePerExtraHour',
                transformBody: (params: any) => {
                  const total =
                    (params.hoursExtra / 3600) * params.valuePerExtraHour;
                  return formatMoney(total);
                },
              },
              {
                label: 'Horas de final de semana',
                key: 'hoursWeekend',
                transform: formatSeconds,
              },
              {
                label: 'Valor da hora de final de semana',
                key: 'weekendValueHour',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Total da hora de final de semana',
                key: 'weekendValueHour',
                transformBody: (params: any) => {
                  const total =
                    (params.hoursWeekend / 3600) * params.weekendValueHour;
                  return formatMoney(total);
                },
              },

              {
                label: 'Alimentação',
                key: 'food',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Pedágio',
                key: 'taxRoad',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Estacionamento',
                key: 'parking',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Hospedagem',
                key: 'accomodation',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Visita',
                key: 'visit',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Quilômetros',
                key: 'mileage',
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Valor por cada quilômetro',
                key: 'valueMileage',
                transform: formatMoney,
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
              {
                label: 'Valor total dos quilômetros',
                key: 'valueMileage',
                transformBody: (value) => {
                  const total = value.mileage * value.valueMileage;
                  return formatMoney(total);
                },
                hidden: (item) => {
                  const value = Number(item);
                  return !value;
                },
              },
            ],
            data: requestDetails,
          },
        ]}
      />
    </div>
  );
};

export default ClosingRequestDetailsList;
